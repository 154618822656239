import { useEffect } from 'react'
import { on, off } from '@/apis/qwebApi'
import { containerValueFinder } from '@/tool'
const defOptions = {}
const Hook = (viewMap, initOptions = defOptions) => {
  useEffect(() => {
    function scrollIntoView({ key, options, delay = 0 }) {
      try {
        const target = viewMap[key]
        const element = containerValueFinder(target)
        setTimeout(() => {
          element.scrollIntoView(options)
        }, delay)
      } catch (error) {
        console.log('useScrollIntoView', error)
      }
    }
    // 临时处理方式
    if (window.$$switchToPath) {
      window.$$switchToPath = false
      scrollIntoView({ key: 'home', options: true, delay: 0, ...initOptions })
    }
    on('scrollIntoView', scrollIntoView)
    return () => off('scrollIntoView', scrollIntoView)
  }, [])
}

export default Hook
